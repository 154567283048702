<!--
 * @Autor: oops.liu
 品牌专场
-->
<template>
  <div class="exclusive-box">
    <navbarbox>
      <van-nav-bar
        :title="seller.actName"
        left-arrow
        :border="false"
        @click-left="LeftHandle"
      />
    </navbarbox>

    <!-- 选择区 -->
    <div class="box">
      <div class="check-domain" v-if="showTop">
        <div class="page_tabs">
          <div class="tabs-left">
            <van-tabs
              v-model="active"
              color="#F02B2B"
              background="transparent"
              title-inactive-color="#333"
              title-active-color="#F02B2B"
              line-height="2"
              line-width="33"
              :swipeable="true"
              @change="tabChange"
            >
              <van-tab
                v-for="item in tabList"
                :key="item.id + 'a'"
                :title="item.name"
                :name="item.id"
              />
            </van-tabs>
          </div>
          <div class="tabs-right" @click="openTabHandle">
            <van-icon name="arrow-down" />
          </div>
        </div>
        <div class="order-list">
          <div class="order-left">
            <order-bar @change="orderChange" v-model="orderActive" />
          </div>
          <div class="order-right" @click="checkLineHandle">
            <van-icon v-if="line" :name="card" />
            <van-icon v-else-if="!line" :name="list" />
          </div>
        </div>
      </div>
    </div>
    <scroll
      @scroll="scrollHandle"
      :listenScroll="true"
      ref="scroll"
      height="calc(100% - 46px)"
      :pulldown="true"
      @pullingUp="handlepullingUp"
    >
      <div class="exclusive">
        <!-- banner -->
        <div class="seller">
          <div class="seller-top" @click="sellerHandle">
            <div class="bg">
              <img v-if="seller.actImg" :src="seller.actImg" alt="" />
            </div>
            <div class="info">
              <div class="active-time">
                距结束<van-count-down
                  :time="seller.endStamp - new Date().getTime()"
                >
                  <template #default="timeData">
                    <div class="time-info" v-if="timeData.days > 1">
                      <span class="days">{{ timeData.days }}</span
                      >天
                    </div>
                    <div class="time-info" v-else>
                      <span class="block">{{ timeData.hours }}</span>
                      <span class="colon">:</span>
                      <span class="block">{{ timeData.minutes }}</span>
                      <span class="colon">:</span>
                      <span class="block">{{ timeData.seconds }}</span>
                    </div>
                  </template>
                </van-count-down>
              </div>
              <div class="logo">
                <img :src="seller.logo" alt="" />
              </div>
              <p class="hint">
                满{{ seller.actPrice }}减{{ seller.actSalePrice }}
              </p>
              <p class="seller-name">{{ seller.actName }}</p>
            </div>
          </div>
        </div>
        <!-- 选择区 -->
        <div class="check-domain" ref="checkDomain">
          <div class="page_tabs">
            <div class="tabs-left">
              <van-tabs
                v-model="active"
                color="#F02B2B"
                background="transparent"
                title-inactive-color="#333"
                title-active-color="#F02B2B"
                line-height="2"
                line-width="33"
                :swipeable="true"
                @change="tabChange"
              >
                <van-tab
                  v-for="item in tabList"
                  :key="item.id + 'a'"
                  :title="item.name"
                  :name="item.id"
                />
              </van-tabs>
            </div>
            <div class="tabs-right" @click="openTabHandle">
              <van-icon name="arrow-down" />
            </div>
          </div>
          <div class="order-list">
            <div class="order-left">
              <order-bar @change="orderChange" v-model="orderActive" />
            </div>
            <div class="order-right" @click="checkLineHandle">
              <van-icon v-if="line" :name="card" />
              <van-icon v-else-if="!line" :name="list" />
            </div>
          </div>
        </div>
        <!-- 商品区 -->
        <div class="goods-list flex_sb" v-if="goodsList.length > 0">
          <template v-if="!line">
            <goods-item
              v-for="item in goodsList"
              :key="item.id + 'b'"
              :info="item"
              height="172px"
              @imgload="imgload"
              @jump="jumpDetail"
            />
          </template>
          <template v-else-if="line">
            <goods-line-item
              v-for="(item, index) in goodsList"
              :key="item.id + 'c' + index"
              :info="item"
              @imgload="imgload"
              @jump="jumpDetail"
            />
          </template>
        </div>
      </div>
    </scroll>
    <div class="pop-box">
      <van-popup
        v-model="showTab"
        position="top"
        closeable
        close-icon="arrow-up"
        :style="{ height: '40%' }"
      >
        <div class="pop-info">
          <div class="top">
            <span>选择商品分类</span>
          </div>
          <div class="pop-content">
            <div
              class="tab-item"
              v-for="(item, index) in tabList"
              :key="item.id + 'd' + index"
              @click="tabChangeHandle(item)"
            >
              <div class="item" :class="{ 'active-item': active === item.id }">
                {{ item.name | lengthFilter }}
              </div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import native from "@/utils/native.js";
import { JUMP_DETAIL, JUMP_SELLER } from "@/mixin/mixin_file.js";
import navbarbox from "@/components/common/navbarbox.vue";
import scroll from "@/components/scroll/index.vue";
import orderBar from "@/components/goods/orderBar.vue";
import goodsItem from "@/components/goods/goodsItem.vue";
import goodsLineItem from "@/components/goods/goodsLineItem.vue";
import _ from "lodash";
export default {
  components: { scroll, goodsItem, goodsLineItem, orderBar, navbarbox },
  props: {},
  data() {
    return {
      line: false,
      seller: {
        id: "",
        actName: "",
        actPrice: "",
        actSalePrice: "",
        endStamp: "",
        actImg: "",
        logo: "",
      },
      active: 3,
      showTab: false,
      card: require("@/assets/img/card_icon.png"),
      list: require("@/assets/img/list_icon.png"),
      offsetTop: 0,
      showTop: false,
      tabList: [],
      goodsList: [],
      pages: 1,
      isHot: true,
      sortColumn: "",
      isAsc: false,
      asc: true,
      orderActive: 1,
    };
  },
  computed: {
    shopId() {
      return this.$route.params.shopId;
    },
  },
  methods: {
    ...mapActions({
      loadCategoryList: "seller/loadGoodsCategory",
      loadGoodsList: "seller/loadGoodsList",
      loadSellerInfo: "active/getseller",
    }),
    refresh: _.debounce(function () {
      this.$refs.scroll.refresh();
    }, 20),
    async getGoodsList() {
      const params = {
        shopId: this.shopId,
        current: this.pages,
        isHot: 1,
        sortColumn: this.sortColumn,
        asc: this.asc,
        categoryFirst: this.active,
        isSale: 1,
      };
      if (!this.isHot) {
        delete params.isHot;
      }
      if (!this.sortColumn) {
        delete params.sortColumn;
      }
      if (!this.isAsc) {
        delete params.asc;
      }
      this.loadGoodsList({ ...params }).then((result) => {
        const list = result.records ? result.records : [];
        this.goodsList = this.goodsList.concat(list);
      });
    },
    LeftHandle() {
      this.$router.back();
    },
    scrollHandle(scroller) {
      this.showTop = -scroller.y > this.offsetTop;
    },
    sellerHandle() {
      let param = { shopId: this.shopId };
      native(JUMP_SELLER, param);
    },
    openTabHandle() {
      this.showTab = true;
    },
    tabChange(name, title) {
      this.pages = 1;
      this.goodsList = [];
      this.getGoodsList();
      this.refresh();
    },
    tabChangeHandle(item) {
      this.pages = 1;
      this.active = item.id;
      this.showTab = false;
      this.goodsList = [];
      this.getGoodsList();
      this.refresh();
    },
    orderChange(item) {
      this.orderActive = item.active;
      this.pages = 1;
      this.sortColumn = item.sortColumn;
      this.goodsList = [];
      if (item.sortColumn === "price_down") {
        this.isAsc = true;
        this.asc = item.asc;
      } else {
        this.isAsc = false;
      }
      if (item.sortColumn === "isHot") {
        this.isHot = true;
      } else {
        this.isHot = false;
      }
      this.getGoodsList();
    },
    jumpDetail(info) {
      const params = { spuId: info.id };
      native(JUMP_DETAIL, params);
    },
    to(id) {
      console.log(id);
    },
    checkLineHandle() {
      this.line = !this.line;
    },
    imgload() {
      this.refresh();
    },
    handlepullingUp() {
      this.pages++;
      console.log(this.pages);
      this.getGoodsList();
    },
  },
  created() {
    this.loadCategoryList(this.shopId).then((list) => {
      this.tabList = list;
      this.active = list[0].id;
      this.getGoodsList();
    });
    this.loadSellerInfo(this.shopId).then((info) => {
      this.seller = info;
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.offsetTop = this.$refs.checkDomain.offsetTop;
    });
  },
  filters: {
    lengthFilter(string) {
      let str;
      if (string.length > 5) {
        str = string.substring(0, 4) + "...";
      } else {
        str = string;
      }
      return str;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
