<template>
  <div class="wrapper" ref="wrapper" :style="{ height: height }">
    <div class="content">
      <slot name="top" v-if="top"> </slot>
      <slot name="default"></slot>
      <slot name="bottom" v-if="bottom"></slot>
    </div>
  </div>
</template>

<script>
import BSroll from "better-scroll";
import _ from "lodash";
export default {
  components: {},
  data() {
    return {
      scroll: null,
    };
  },
  props: {
    /*
        0，不会派发滚动事件
        1，滚动的时候会派发scroll事件，会截流。
        2 ，滚动的时候实时派发scroll事件，不会截流。
        3， 除了实时派发scroll事件，在swipe的情况下仍然能实时派发scroll事件 
        */
    top: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "100vh",
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    probeType: {
      type: Number,
      default: 0,
    },
    //  点击列表是否派发click事件
    click: {
      type: Boolean,
      default: true,
    },
    //  是否开启横向滚动
    scrollX: {
      type: Boolean,
      default: false,
    },
    //  是否派发滚动事件
    listenScroll: {
      type: Boolean,
      default: false,
    },
    //  列表的数据
    data: {
      type: Array,
      default: null,
    },
    /** 是否派发滚动到底部的事件，用于上拉加载 */
    pullup: {
      type: Boolean,
      default: false,
    },
    /** 是否派发顶部下拉的事件，用于下拉刷新 */
    pulldown: {
      type: Boolean,
      default: false,
    },
    /** 是否派发列表滚动开始的事件 */
    beforeScroll: {
      type: Boolean,
      default: false,
    },
    /**  当数据更新后，刷新scroll的延时。 */
    refreshDelay: {
      type: Number,
      default: 20,
    },
  },
  computed: {},
  watch: {},
  methods: {
    // 滚动到指定位置 ;滚动到某个位置，x,y 代表坐标，time 表示动画时间，easing 表示缓动函数
    scrollTo(x, y, time = 300) {
      this.scroll.scrollTo(x, y, time);
    },
    // 用于重新加载上拉加载
    finishPullUp() {
      this.scroll && this.scroll.finishPullUp();
    },
    // 刷新;强制 scroll 重新计算，当 better-scroll 中的元素发生变化的时候调用此方法
    refresh() {
      this.scroll && this.scroll.refresh();
    },
    // 用于重新加载下拉刷新
    finishPullDown() {
      this.scroll && this.scroll.finishPullDown();
    },
    getScrollY() {
      return this.scroll ? this.scroll.y : 0;
    },
  },
  created() {},
  mounted() {
    // 创建BScroll对象
    this.$nextTick(() => {
      if (!this.$refs.wrapper) {
        return;
      }
      this.scroll = new BSroll(this.$refs.wrapper, {
        probeType: this.probeType, //1.滚动的时候会派发scroll事件，会截流 2. 滚动的时候实时派发scroll事件，不会截流 3. 除了实时派发scroll事件，在swipe的情况下仍然能实时派发scroll事件, (默认值: 0 不派发scroll事件)
        click: this.click, //是否派发click事件，通常判断浏览器派发的click还是betterscroll派发的click，可以用_constructed，若是bs派发的则为true
        //下拉刷新，可以直接传入true，而不是对象
        pullUpLoad: {
          threshold: -100, // 当上拉距离超过100px时触发 pullingUp 事件
        },
        //上拉加载,可以直接传入true，而不是对象
        pullDownRefresh: {
          threshold: 100, // 下拉距离超过100px触发pullingDown事件
          stop: 0, // 回弹停留在距离顶部0px的位置
        },
        scrollX: this.scrollX, //滚动方向为 X 轴
        startX: 0, //开始的X轴位置
        startY: 0, //开始的Y轴位置
        scrollY: true, //滚动方向为 Y 轴
        directionLockThreshold: 5,
        momentum: true, // 当快速滑动时是否开启滑动惯性
        bounce: true, // 是否启用回弹动画效果
        scrollbar: false, //(默认值:false) 滚动条
        selectedIndex: 0, // wheel 为 true 时有效，表示被选中的 wheel 索引
        rotate: 25, //wheel 为 true 时有效，表示被选中的 wheel 每一层的旋转角度
        wheel: false, //该属性是给 picker 组件使用的，普通的列表滚动不需要配置
        snap: false, //该属性是给 slider 组件使用的，普通的列表滚动不需要配置
        snapLoop: false, //是否可以无缝循环轮播
        snapThreshold: 0.001, //用手指滑动时页面可切换的阈值，大于这个阈值可以滑动的下一页
        snapSpeed: 400, //轮播图切换的动画时间
        swipeTime: 1200, //swipe 持续时间
        bounceTime: 700, // 弹力动画持续的毫秒数
        adjustTime: 400, // wheel 为 true 有用，调整停留位置的时间
        swipeBounceTime: 1200, //swipe 回弹 时间
        deceleration: 0.001, //滚动动量减速越大越快，建议不大于0.01
        momentumLimitTime: 300, //符合惯性拖动的最大时间
        momentumLimitDistance: 5, // 符合惯性拖动的最小拖动距离
        resizePolling: 60, //重新调整窗口大小时，重新计算better-scroll的时间间隔
        preventDefault: true, // 是否阻止默认事件
        preventDefaultException: {
          tagName: /^(INPUT|TEXTAREA|BUTTON|SELECT)$/,
        }, // 阻止默认事件
        HWCompositing: true, //是否启用硬件加速
        useTransition: true, //是否使用CSS3的Transition属性
        useTransform: true, //是否使用CSS3的Transform属性
      });
      //监听滚动位置
      if (this.listenScroll) {
        
        this.scroll.on("scroll", (position) => {
          // console.log(position);
          this.$emit("scroll", position);
        });
      }

      // 滚动开始之前触发
      if (this.beforeScroll) {
        this.scroll.on("beforeScrollStart", () => {
          // console.log('加载');
          this.$emit("beforeScrollStart ");
        });
      }
      // // 手指移开屏幕时触发
      // this.scroll.on('touchend', (pos) => {
      //     console.log('下拉刷新')
      //     if (pos.y > 50) {
      //         console.log('下拉刷新成功')
      //     }
      //     this.$emit('touchend', pos)
      // })
      if (this.pulldown) {
        this.scroll.on("pullingUp", () => {
          console.log("上拉加载");
          this.$emit("pullingUp");
          console.log("上拉加载，事件完成之后");
          this.scroll.refresh();
          this.scroll.finishPullUp(); //事情做完，需要调用此方法告诉 better-scroll 数据已加载，否则下拉事件只会执行一次这个操作
        });
      }
      // 滚动开始时触发
      // this.scroll.on('scrollStart', () => {
      //     // console.log('加载');
      //     this.$emit('scrollStart')
      // })
      // this.scroll.on('scrollEnd', (pos) => {
      //     console.log('加载', pos)
      //     this.$emit('scrollEnd', pos)
      // })
      // 是否派发滚动到底部事件，用于上拉加载
      if (this.pullup) {
        this.scroll.on("pullingDown", () => {
          console.log("下拉刷新");
          this.$emit("pullingDown");
          this.scroll.refresh();
          this.scroll.finishPullDown(); //每次上拉结束后，需要执行这个操作，事情做完，需要调用此方法告诉 better-scroll 数据已加载，否则下拉事件只会执行一次
          console.log("下拉加载事件完成之后");
        });
      }
      // 取消滚动时触发
      // this.scroll.on('scrollCancel', () => {
      //     console.log('加载取消')
      //     this.$emit('scrollCancel')
      // })
    });
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>
<style scoped>
.wrapper {
  width: 100%;
  box-sizing: border-box;
}
</style>
